.table-container {
  display: flex;
  width: 100%;
  justify-content: center;
  border-collapse: collapse;
}
.table {
  width: 90%;
}

.header-row {
  font-weight: bold;
}

.header-cell {
  display: table-cell; /* Display column headers as table cells */
  height: 4px;
  line-height: 20px;
  font-size: 1em;
  border: none; /* Remove cell border from header cells */
  text-align: center; /* Center align the text horizontally in header cells */
}
.character-row {
  width: 13%;
  height: 10%;
}

.table-cell {
  display: table-cell;
  width: 13%;
  height: 10vh;
  border: 2px solid #505050;
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.9em;
  text-align: center; /* Center align the text horizontally in cells */
}

/* Custom styles for specific cells */
.name-cell {
  width: 20%;
  height: 20%;
}

.image {
  width: 60%;
}

.custom-name {
  font-size: 1.2em;
  font-weight: bold;
}

@media (max-width: 767px) {
  .table-cell {
    display: table-cell;
    width: 14%;
    height: 50px;
    border: 2px solid #8e8c8c;
    border-radius: 5px;
    font-weight: bold;
    font-size: 0.9em;
    text-align: center; /* Center align the text horizontally in cells */
  }
  .image {
    width: 100%;
  }

  .custom-name {
    font-size: 0.8em;
    font-weight: bold;
  }
}

/* Animations */
@keyframes fadeInAndRotateAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.correct {
  background-color: rgba(0, 255, 0, 0.5); /* Green background with 50% opacity */
  animation: fadeInAndRotateAnimation 0.8s ease-in-out, fadeInBackgroundColor 0.8s ease-in-out;
  animation-fill-mode: forwards;
}

.wrong {
  background-color: rgba(209, 86, 86, 0.5); /* Red background with 50% opacity */
  animation: fadeInAndRotateAnimation 0.8s ease-in-out, fadeInBackgroundColor 0.8s ease-in-out;
  animation-fill-mode: forwards;
}

.translucent-background {
  background-color: rgba(245, 61, 61, 0.5); /* White background with 50% opacity (translucent) */
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}
