.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-image {
  width: 100%;
  max-width: 150px;
  height: auto;
}

.custom-title {
  padding-left: 80px;
  font-size: 3em;
  font-weight: bold;
  font-family: "Comic Sans MS";
}

.author {
  font-size: 20px;
  color: #777;
}

@media (max-width: 767px) {
  .title-image {
    width: 100%;
    max-width: 100px;
    height: auto;
  }

  .custom-title {
    padding-left: 80px;
    font-size: 1.9em;
    font-weight: bold;
    font-family: "Comic Sans MS";
  }
}
