body {
  cursor: url("./cursor.cur"), auto;
  background-image: url("/public/background-min.png");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  padding-top: 70px;
}
::-webkit-scrollbar {
  display: none;
}

.center-box {
  width: 60%;
  height: 100%;
  margin: auto auto;
  background-color: #dbdbdb;
  border-radius: 30px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(121, 80, 80, 0.1);
  opacity: 0.9;
  font-size: 16px;
  line-height: 2;
}

@media (max-width: 767px) {
  .center-box {
    width: 90%;
    padding: 10px;
    border-radius: 20px;
    font-size: 14px;
    line-height: 1.6;
  }
}
