.character-option {
  background-color: rgb(196, 196, 196);
  align-items: center;
  display: flex;
  padding: 20px;
  font-weight: bold;
}

.character-option-image {
  width: 30%;
  height: 30%;
  margin-right: 8px;
}
.character-option.disabled-option {
  color: #999;
  pointer-events: none;
}

.character-option.highlighted {
  background-color: rgb(145, 145, 145);
}
.character-option-image.disabled-image {
  filter: grayscale(100%);
}

.character-search {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
