.lose-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background to darken the screen */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000;
}

.lose-modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.lose-modal h2 {
  margin-bottom: 10px;
}

.lose-modal button {
  cursor: pointer;
}
