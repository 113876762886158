.game-container {
  align-items: flex;
  padding: 2%; /* Add padding as needed */
}

.scorestreak {
  font-size: 1.2em;
}

@media (max-width: 767px) {
  .game-container {
    padding: 0%;
  }
}
