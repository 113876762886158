.helpModal {
  width: 40%;
  background: #c0c0c0;
  border: "2px solid #000";
  border-radius: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .scrollable-content {
    max-height: 80vh; /* Set a maximum height for the content */
    overflow-y: auto; /* Enable vertical scrolling */
  }
}

.title {
  font-weight: bold;
  text-align: center;
  padding-top: 10px;
  font-size: 3em;
}

.description {
  text-align: center;
  font-size: 150%;
  font-family: "Comic Sans MS";
}

.description-image {
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 95%;
  height: auto;
  display: block;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .helpModal {
    width: 90%;
  }
}
