.customAppBar {
  background-color: #1d1c1c;
  box-shadow: none;
}

.customTypography {
  color: white;
  font-family: "Comic Sans MS";
  font-weight: bold;
  font-size: 2em;
}

.leftElements {
  margin-left: auto;
}
.helpButton {
  font-family: "Comic Sans MS";
  font-weight: bold;
  color: white;
  padding: 20px;
}

.version {
  font-family: "Comic Sans MS";
  font-size: 1em;
}
